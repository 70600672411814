.header {
  width: 100%;
  height: 64px;
  background: #fff;
  // border-bottom: 2px solid $grey-30;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);

  &__settings {
    border: 1px solid transparent;
    border-radius: 50%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_container {
      margin-right: 20px;
    }

    &:hover {
      background-color: #e7e7e7;
    }

    &_dropdown {
      width: 320px;
      border-radius: 4px;
      border: 1px solid var(--black-15, rgba(51, 51, 51, 0.15));
      background: #FFF;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      padding: 8px 0px;
    }
  }

  &__inner {
    height: 100%;
    padding: 0 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .back-link {
      a {
        display: flex;
        align-items: center;
        font-family: $display-font-family;

        svg {
          width: 24px;
          height: 24px;
        }

        &:hover {
          text-decoration-color: $black;
        }
      }
    }
  }

  &__logo {
    position: relative;
    margin-right: 2rem;
    padding-right: 2rem;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__nav-left {
    display: flex;
    align-items: center;
  }
  &__user {
    border-left: 1px solid #A1A0A44D;
    height: 65px;
    display: flex;
    align-items: center;
  }
}

.settings {

  &__label {
    color: var(--base-monochrome-black-80, rgba(51, 51, 51, 0.80));
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    line-height: 150%;
  }

  &__paragraph__label {
    padding: 12px 24px;
    font-weight: 500;
    color: var(--base-monochrome-black-80, rgba(51, 51, 51, 0.80));
    font-family: Rubik;
    font-size: 12px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: 0.5px;
    margin: 0px;
  }
  &__button__label {
    position: relative;
    width: 16px;
    height: 16px;
  }
  &__box__arrow {
    position: absolute;
    right: 25px;
  }

}

li.settings__button__background {
  padding: 12px 40px;

  a {
    padding-top: 1rem;
    vertical-align: center;

    svg {
      margin-left: -1rem;
      margin-right: 1rem;
      z-index: 9999;
    }
  }
}

li.settings__button__background:hover {
  background-color: #A2E9DC;
  width: 320px;
}

.settings__link {
  font-size: 14px;

  &:hover {
    background-color: transparent;
    text-decoration: none !important;
  }
}

.header__nav-right {
  span {
    display: none;

    @media (min-width: $bp-sm) {
      display: inline-block;
    }
  }

  .button {
    margin-left: 1.25rem;
  }
}
